import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const Ourgallery = () => {
  return (
    <>
      <Helmet>
        <title>gallery</title>
      </Helmet>
      <div className="charity-subheader">
        <span className="black-transparent"></span>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>
                Our <span className="charity-color">Gallery</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="charity-main-section charity-simple-gallery-full">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="charity-fancy-title">
                {/* <h2>Our Gallery</h2> */}
              </div>
              <div className="charity-gallery charity-simple-gallery">
                <ul className="row">
                  <div className="charity-fancy-title ">
                    <center>
                      <h2>
                        Memories of <span style={{ color: "blue" }}>2022</span>
                      </h2>
                    </center>
                  </div>
                  <hr />
                  <li className="col-md-2 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img1.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img1.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img2.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img2.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-2 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img3.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img3.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img4.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img4.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img5.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img5.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-2 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img6.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img6.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-2 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img7.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img7.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>

                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img8.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img8.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>

              <div className="charity-gallery charity-simple-gallery">
                <ul className="row">
                  <div className="charity-fancy-title ">
                    <center>
                      <h2 className="mt-4">
                        Memories of <span style={{ color: "blue" }}>2021</span>
                      </h2>
                    </center>
                  </div>
                  <hr />
                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img9.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img9.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>

                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img10.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img10.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>

                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img11.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img11.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>

                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img12.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img12.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>

                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img13.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img13.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img14.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img14.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>

                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img15.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img15.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img16.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img16.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>

                  <li className="col-md-4 px-0">
                    <figure>
                      <Link href="#">
                        <img
                          src="gallery_imgs/Gallery_img17.jpg"
                          alt=""
                          style={{ width: "100%", height: "303px" }}
                        />
                      </Link>
                      <figcaption>
                        <span></span>
                        <Link
                          data-fancybox="gallery"
                          href="gallery_imgs/Gallery_img17.jpg"
                        >
                          <i className="fa fa-search-plus"></i>
                        </Link>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ourgallery;
