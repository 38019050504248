import React from "react";
import { Link } from "react-router-dom";
import MissionCard from "./SubComponent/MissionCard";
import MainMid from "./SubComponent/MainMid";
import LatestCauses from "./SubComponent/LatestCauses";
import BlackBoxCounter from "./SubComponent/BlackBoxCounter";
import LatestEvents from "./SubComponent/LatestEvents";
import OurGallery from "./SubComponent/OurGallery";
import Volunteers from "./SubComponent/Volunteers";
import OurBlog from "./SubComponent/OurBlog";
import Vision from "./SubComponent/Vision";
import { Helmet } from "react-helmet";
import ControlledCarousel from "./CommanComponent/Carousel";
import CarouselComp from "./CommanComponent/Carousel";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="charity-banner">
        {/* <div className="charity-banner-layer">
          <span className="charity-banner-transparent"></span>
          <img
            src="images/IMG-20220311-WA0012.jpg"
            style={{ width: "100%", height: "80vh" }}
            alt=""
          />
          <div className="charity-banner-caption">
            <div className="container">
              <h1>
                Serving underserved /
                <span className="charity-color"> deprived community</span>
              </h1>
              <div className="clearfix"></div>
              <p>
                We are committed to serve the community regardless of caste,
                creed, color, Gender etc.
              </p>
              <div className="clearfix"></div>
              <Link to="#" style={{ textDecoration: "none" }}>
                Learn More
              </Link>
              <Link to="#" style={{ textDecoration: "none" }}>
                Donate Now
              </Link>
            </div>
          </div>
        </div> */}
        <CarouselComp/>
      </div>
      {/* =================================Banner carousel start======================= */}
      {/*  */}
      <div className="charity-main-content">
        <MissionCard />
      </div>
      <div className="charity-main-content">
        <Vision />
      </div>
      {/* <MainMid/>, */}
      {/* <LatestCauses/>, */}
      {/* <div className="charity-main-section charity-simple-team-full">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="charity-fancy-title">
                <h2>OUR IMPACT</h2>
              </div>
              <BlackBoxCounter />
            </div>
          </div>
        </div>
      </div> */}
      {/* <LatestEvents/>, */}
      <OurGallery />,
      <Volunteers />,{/* <OurBlog/> */}
    </>
  );
};
export default Home;
