import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const OurPartners = () => {

    return (
      <>
        <Helmet>
          <title>partners</title>
        </Helmet>
        <div className="charity-subheader">
          <span className="black-transparent"></span>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>
                  Our <span className="charity-color"> Partners </span>
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="charity-main-content">
          <div className="charity-main-section">
            <div className="container">
              {/* <div className="col-md-12"> */}
              <div className="charity-fancy-title"></div>

              {/* <div class="row"> */}
              <div class="row">
                <div class="col-lg-3">
                  {/* <figure class="short-text-thumb"> */}
                  <img
                    src="gallery_imgs/avtar.jpg"
                    alt=""
                    width="193"
                    height="130"
                  />
                  {/* </figure> */}
                  <h4>Parther Name</h4>
                </div>
                <div class="col-lg-9 charity-short-text">
                  <h2>Parther Title</h2>

                  <p>
                    Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners. Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners. Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners.
                  </p>

                  {/* <Link to="#" class="charity-more-btn"><i class="fas fa-arrow-right"></i> Read More</Link>  */}
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-lg-9 charity-short-text">
                  <h2>Parther Title</h2>
                  <p>
                    Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners. Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners. Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners.
                  </p>

                  {/* <Link to="#" class="charity-more-btn"><i class="fas fa-arrow-right"></i> Read More</Link>  */}
                </div>

                <div class="col-lg-3">
                  {/* <figure class="short-text-thumb"> */}
                  <img
                    src="gallery_imgs/avtar.jpg"
                    alt=""
                    width="193"
                    height="130"
                  />
                  {/* </figure> */}
                  <h4>Parther Name</h4>
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-lg-3">
                  {/* <figure class="short-text-thumb"> */}
                  <img
                    src="gallery_imgs/avtar.jpg"
                    alt=""
                    width="193"
                    height="130"
                  />
                  {/* </figure> */}
                  <h4>Parther Name</h4>
                </div>
                <div class="col-lg-9 charity-short-text">
                  <h2>Parther Title</h2>

                  <p>
                    Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners. Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners. Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners.
                  </p>

                  {/* <Link to="#" class="charity-more-btn"><i class="fas fa-arrow-right"></i> Read More</Link>  */}
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-lg-9 charity-short-text">
                  <h2>Parther Title</h2>
                  <p>
                    Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners. Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners. Paragraph about oru
                    partners.Paragraph about oru partners.Paragraph about oru
                    partners.
                  </p>

                  {/* <Link to="#" class="charity-more-btn"><i class="fas fa-arrow-right"></i> Read More</Link>  */}
                </div>

                <div class="col-lg-3">
                  {/* <figure class="short-text-thumb"> */}
                  <img
                    src="gallery_imgs/avtar.jpg"
                    alt=""
                    width="193"
                    height="130"
                  />
                  {/* </figure> */}
                  <h4>Parther Name</h4>
                </div>
              </div>
              <hr />

              {/* </div> */}

              {/* </div> */}
            </div>
          </div>
        </div>
      </>
    );
}
export default OurPartners;