import React from "react";
import { Helmet } from "react-helmet";

const CapacityBuilding = () => {
  return (
    <>
      <Helmet>
        <title>capacity building</title>
      </Helmet>
      <div
        className="charity-subheader"
        style={{
          backgroundImage:
            "url(images/capacity-building/capacity_building_1.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <span className="black-transparent"></span>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>
                Capacity <span className="charity-color">building</span>{" "}
              </h1>
              {/* <p> Write Counselling subtittle or short discription here.</p> */}
            </div>
          </div>
        </div>
      </div>

      {/* Gallery start*/}

      {/* Gallery End */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <figure>
              <img
                src="images/capacity-building/capacity_building_1.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/capacity-building/capacity_building_2.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/capacity-building/capacity_building_3.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
        </div>

        <div class="row">
          <div className="col-md-2"> </div>
          <div class="col-md-8 charity-short-text">
            <p>
              Developing and harnessing expertise or skills in a developing
              country like India is as important if not more than academic
              education. The Goal of our Skills Development Program is to equip
              under privileged girls with skills they can leverage to avail
              employment opportunities and strive for women empowerment.
            </p>
            <p>
              Girls from economically weaker or marginalized backgrounds are
              deprived of access to skills building programs because of
              financial constraints, shortfalls in the education system,
              inaccessible learning platforms, gender inequality, and primeval
              social belief systems.
            </p>

            <p>
              <b>Shortfalls in the education system : </b> India has only 2% of
              the workforce skilled compared with over 40% in countries like
              China, USA and Germany due to lack of inclusion of skills training
              in the formal Indian education system.
            </p>
            <p>
              <b> Inaccessible learning platforms : </b> According to Internet
              and Mobile Association of India, only 3% of rural Indians are
              connected to the Internet. This culminates in lack access to
              online content, paid learning opportunities and programs run in
              urban areas.
            </p>
            <p>
              <b>Financial Constraints : </b>  Almost 22% of the population in
              India is below its official poverty line limiting access to paid
              skills building opportunities.
            </p>
            <p>
              <b>Social Beliefs: </b> In a family with meagre resources, boys
              are prioritized in reaping additional learning opportunities
              because educated women are assumed to be less sought for in a
              marriage setting.
            </p>
            <p>
              <b>Our Skills Development Program </b>  ensures paid skills
              building opportunities, access to nutritious food and good
              healthcare in a safe and progressive environment. We take pride in
              having a direct relationship with these girls and their parents to
              ensure that we are not only providing them financial support but
              also acting as their guardians providing them career and other
              guidance.
            </p>

            <p>
              Ansar welfare Trust rolled out the Skills Development program in
              2017 and expect to finalize the first location of the academy in
              2018. Our initial plan is to form strategic partnerships with some
              of the existing Skills Development Institutes to meet the
              vocational skills building needs of the girls enrolled through
              ‘Support-A-Girl’ program. Ansar welfare Trust will offer
              ‘Scholarship Program’ to eligible girls in the Coaching Centre. We
              will also partner with various companies with employment needs by
              providing relevant training to the girls in the foundation who are
              interested to work with these companies. Our Mission is to
              transition more and more girls from our Support-A-Girl program to
              the Skills Development program, by equipping them with the skills
              required to become independent and self-empowered. We need help
              and support of donors, volunteers and supporters like you who can
              help us accomplish our mission.
            </p>
          </div>
          <div className="col-md-2"> </div>
          {/*  */}
        </div>

        <div className="row">
          <div className="col-md-4">
            <figure>
              <img
                src="images/capacity-building/capacity_building_4.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/capacity-building/capacity_building_5.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/capacity-building/capacity_building_6.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default CapacityBuilding;
