import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const EventList = () =>{

    return (
      <>
        <Helmet>
          <title>our-team</title>
        </Helmet>
        <div className="charity-subheader">
          <span className="black-transparent"></span>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>
                  Our <span className="charity-color">Team</span>
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="charity-main-content">
          <div className="charity-main-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="charity-team charity-simple-team inner-wrap">
                    <ul className="row">
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          {/* <a href="#"> */}
                          <img
                            src="images/team/nazma.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          {/* </a> */}
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="" style={{ textDecoration: "none" }}>
                                  Nazma Begum
                                </a>
                              </h2>
                              <span>Founder </span>
                              <p>Retired Teacher</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          {/* <a href="#"> */}
                          <img
                            src="images/team/ashma.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          {/* </a> */}
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="" style={{ textDecoration: "none" }}>
                                  Asma Arshi
                                </a>
                              </h2>
                              <span>Director</span>
                              <p>Women And Child Rights Activist</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/samina.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="" style={{ textDecoration: "none" }}>
                                  Samina Arshi
                                </a>
                              </h2>
                              <span>Trustee State head (Telangana) </span>
                              <p>Educationist</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/laiq.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Laiq Ahmed
                                </a>
                              </h2>
                              <span> Vice President</span>
                              <p>Banker</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/iqra.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Iqra Ansari
                                </a>
                              </h2>
                              <span>State Head (U.P)</span>
                              <p>Social Worker</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/aarif.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Mohd Aarif{" "}
                                </a>
                              </h2>
                              <span>Executive Member</span>
                              <p>Social Worker</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/mohtamim.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Md. Mohtamim Yazdani
                                </a>
                              </h2>
                              <span>Executive Member</span>
                              <p>Ex Member CWC</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <a href="#">
                            <img
                              src="images/team/mustafa.jpg"
                              alt=""
                              style={{
                                objectFit: "cover",
                                objectPosition: "80% 100%",
                              }}
                            />
                          </a>
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Mustafa
                                </a>
                              </h2>
                              <span>Executive Member</span>
                              <p>Social Worker</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/abdullah.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Abdullah{" "}
                                </a>
                              </h2>
                              <span>Treasurer</span>
                              <p>Engineer</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/sumbul.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Sumbul
                                </a>
                              </h2>
                              <span>Executive Member</span>
                              <p>Student</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>

                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/zainul.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  S.M.Zainul Abedien
                                </a>
                              </h2>
                              <span>Core Committee</span>
                              <p>Senior Journalist</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ height: "500px" }}>
                          <img
                            src="images/team/zafar.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "80% 100%",
                            }}
                          />
                          <figcaption>
                            <div className="charity-simple-team-media">
                              <a href="#">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div
                              className="charity-simple-team-text"
                              style={{ marginBottom: "15px" }}
                            >
                              <h2>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Md Zafar Hasan
                                </a>
                              </h2>
                              <span>Executive Member</span>
                              <p>Printing</p>
                            </div>
                          </figcaption>
                        </figure>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="charity-pagination">
                    <ul className="page-numbers">
                       <li><a className="previous page-numbers" href="404.html"><span aria-label="Next"><i className="fa fa-angle-left"></i> Previous Post</span></a></li>
                       <li><span className="page-numbers current">01</span></li>
                       <li><a className="page-numbers" href="404.html">02</a></li>
                       <li><a className="page-numbers" href="404.html">03</a></li>
                       <li><a className="page-numbers" href="404.html">...</a></li>
                       <li><a className="page-numbers" href="404.html">10</a></li>
                       <li><a className="next page-numbers" href="404.html"><span aria-label="Next">Next Post<i className="fa fa-angle-right"></i></span></a></li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default EventList;