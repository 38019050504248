

import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Footer from './Component/CommanComponent/Footer';
import Header from './Component/CommanComponent/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./Component/Home"  
import OurTeam from "./Component/EventList";
import EventGrid from "./Component/EventGrid";
import EventDetail from "./Component/EventDetail";
import Coaching from "./Component/Coaching";
import Scholarship from "./Component/Scholarship";

// import Funder from "./Component/Funder";
import OurPartners from "./Component/OurPartners";

import NotfoundPgae from "./Component/404page";
import Contact from "./Component/Contact";
import Ourgallery from "./Component/Ourgallery";
import RationDistribution from "./Component/RationDistribution";
import WidoPension from "./Component/WidoPension";
import Counselling from "./Component/Counselling";
import About from "./Component/About";

import WomenEmpowerment from './Component/WomenEmpowerment';
import CapacityBuilding from './Component/CapacityBuilding';
import OurImpact from './Component/OurImpact';
import Acknowledgement from "./Component/Acknowledgement";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/women-empowerment" element={<WomenEmpowerment />} />
          <Route path="/coaching" element={<Coaching />} />
          <Route path="/counselling" element={<Counselling />} />
          <Route path="/capicity-building" element={<CapacityBuilding />} />
          <Route path="/acknowledgement" element={<Acknowledgement />} />
          <Route path="/partner" element={<OurPartners />} />
          <Route exact path="/impact" element={<OurImpact />} />
          <Route path="/gallery" element={<Ourgallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route exact path="/*" element={<NotfoundPgae />} />
          {/* 
          
          <Route path="/founders" element={<EventGrid />} />
          <Route path="/EventDetail" element={<EventDetail />} />
         
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/BlogLarge" element={<BlogLarge />} />
          <Route path="/Blog_medium" element={<BlogMedium />} />
          <Route path="/Blog_detail" element={<BlogDetail />} />
         
          <Route path="/well-wisher" element={<TeamClassic />} />
          <Route path="/about" element={<About />} />
          <Route path="/prayer_list" element={<PrayerList />} />
          <Route path="/prayer_grid" element={<PrayerGrid />} />
          <Route path="/Prayer_detail" element={<PrayerDetail />} />
          <Route path="/notfoundPgae" element={<notfoundPgae />} />
          <Route path="/SearchResult" element={<SearchResult />} />
         
          <Route path="/capicity-building" element={<CapacityBuilding />} />
          <Route
            path="/standing-tall-in-the-society"
            element={<StandingTallSociety />}
          />

          <Route
            exact
            path="/ration_distribution"
            element={<RationDistribution />}
          /> */}
          {/*  */}

          {/* <Route exact path="/widow_pension" element={<WidoPension />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
