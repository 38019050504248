import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from "components/ExampleCarouselImage";
// const carouselItemsArray = [
//   {
//     image: "images/IMG-20220311-WA0012.jpg",
//     heading: "",
//     desc: "",
//   },
//   {
//     image: "images\coaching\coaching_6.jpg",
//     heading: "",
//     desc: "",
//   },
//   {
//     image: "",
//     heading: "",
//     desc: "",
//   },
// ];

function CarouselComp() {
   const [index, setIndex] = useState(0);

   const handleSelect = (selectedIndex) => {
     setIndex(selectedIndex);
   };
  return (
    <Carousel fade activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img
          src="images/home/home_1.jpg"
          alt=""
          className="d-block w-100"
          style={{ height: "600px" }}
        />
        <Carousel.Caption>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src="images/home/home_2.jpg"
          alt=""
          className="d-block w-100"
          style={{ height: "600px" }}
        />
        <Carousel.Caption>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src="images/home/home_3.jpg"
          alt=""
          className="d-block w-100"
          style={{ height: "600px" }}
        />
        <Carousel.Caption>
          {/* <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComp;
