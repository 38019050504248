import React from "react";
import { Helmet } from "react-helmet";

const WomenEmpowerment = () => {
  return (
    <>
      <>
        <Helmet>
          <title>women empowerment</title>
        </Helmet>
        <div>
          <div
            className="charity-subheader"
            style={{
              backgroundImage: "url(images/women-empower/women-empower1.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <span className="black-transparent"></span>

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1>
                    Women <span className="charity-color">empowerment</span>{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery start*/}

          {/* <div className="">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="charity-fancy-title">
                  </div>
                  <div className="charity-gallery charity-simple-gallery">
                    <ul className="row">
                      <li className="col-md-12 px-3">
                        <figure>
                          <img
                            src="gallery_imgs/Gallery_img1.jpg"
                            alt=""
                            style={{ width: "100%", height: "303px" }}
                          />
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="charity-fancy-title">
                  </div>
                  <div className="charity-gallery charity-simple-gallery">
                    <ul className="row">
                      <li className="col-md-12 px-3">
                        <figure>
                          <img
                            src="gallery_imgs/Gallery_img1.jpg"
                            alt=""
                            style={{ width: "100%", height: "303px" }}
                          />
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="charity-fancy-title">
                  </div>
                  <div className="charity-gallery charity-simple-gallery">
                    <ul className="row">
                      <li className="col-md-12 px-3">
                        <figure>
                          <img
                            src="gallery_imgs/Gallery_img1.jpg"
                            alt=""
                            style={{ width: "100%", height: "303px" }}
                          />
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Gallery end */}

          <div className="">
            <div className="container-fluid">
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-md-4">
                    <figure>
                      <img
                        src="images/womenempowerment/women_empower_1.jpg"
                        alt=""
                        style={{ width: "100%", height: "303px" }}
                      />
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <figure>
                      <img
                        src="images/womenempowerment/women_empower_2.jpg"
                        alt=""
                        style={{ width: "100%", height: "303px" }}
                      />
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <figure>
                      <img
                        src="images/womenempowerment/women_empower_3.jpg"
                        alt=""
                        style={{ width: "100%", height: "303px" }}
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div class="row">
                <div className="col-md-2"> </div>
                <div class="col-md-8 charity-short-text">
                  <h2>WHAT IS WOMEN EMPOWERMENT?</h2>

                  <p>
                    Women’s empowerment refers to the process of enabling women
                    to have greater control over their lives and to be able to
                    make their own decisions. This can include empowering women
                    to participate fully in the economy and in the political
                    process, as well as empowering them to make decisions about
                    their own health and well-being. Women’s empowerment is
                    important because it can lead to a range of positive
                    outcomes, including increased economic growth and
                    development, improved health and well-being, and greater
                    gender equality. In India, women’s empowerment has been a
                    key issue for many years.
                  </p>
                  <p>
                    Despite some progress in recent decades, women in India
                    continue to face significant challenges when it comes to
                    gender equality. These challenges include discrimination,
                    lack of access to education and employment, and gender-based
                    violence.
                  </p>
                  <h2>HOW THIS DISCRIMINATION SHOW UP IN OUR SOCIEY:</h2>
                  <p>
                    <b>Discrimination : </b> Women in India often face
                    discrimination in many areas of life, including in the home
                    and in the workplace. This can make it difficult for them to
                    access education and employment opportunities, and can lead
                    to unequal treatment and lower pay.
                  </p>
                  <p>
                    <b>Lack of access to education and employment: </b> Women in
                    India face significant barriers when it comes to accessing
                    education and employment. For example, data from the World
                    Bank shows that only around 50% of women in India
                    participate in the labour force, compared to around 80% of
                    men. This is due in part to cultural and social barriers
                    that prevent women from participating in the workforce, as
                    well as lack of access to education and training.
                  </p>
                  <p>
                    <b>Gender-based violence</b>  is a major problem in
                    India. The National Crime Records Bureau reports that in
                    2021, there were over 428,278 reported cases of violence
                    against women in India. This included incidents of physical
                    and sexual assault, as well as dowry- related violence and
                    female infanticide. The statistics are alarming, with 31,677
                    reported rapes against women in 2021 alone.
                  </p>
                  <p>
                    <b>Poverty: </b> Many women in India live in poverty,
                    particularly in rural areas. Poverty can make it difficult
                    for women to access education and employment, and can lead
                    to other challenges such as poor health and malnutrition.
                  </p>
                  <p>
                    <b>Disparities between urban and rural areas: </b> Women in
                    rural areas of India often face greater challenges when it
                    comes to empowerment. For example, they may have less access
                    to education and health care, and may be more isolated and
                    vulnerable to gender- based violence.
                  </p>
                  <h2>WHAT IS WOMEN EMPOWERMENT?</h2>

                  <p>
                    Women’s empowerment can have a range of {" "}
                    <b>positive impacts on women’s lives,</b> including improved{" "}
                    <b>economic opportunities,</b>  greater
                    <b>control over their own lives,</b>   increased{" "}
                    <b> political participation,</b>   and greater{" "}
                    <b>gender equality.</b>  By empowering women to
                  </p>
                  <p>
                    participate fully in the economy, women can have access to {" "}
                    <b>better paying jobs,</b> which can help to lift them
                    and their families out of poverty. Empowering women can also
                    give them more control over their own lives, allowing them
                    to make decisions about their 
                    <b>own health, well-being, and future,</b>  which can lead
                    to increased self-esteem and confidence, as well as improved
                    mental and physical health.
                  </p>
                  <p>
                    When women are empowered to participate in the political
                    process, they can have a greater say in the decisions that
                    affect their lives and their communities, leading to more
                    inclusive and representative decision-making and policies
                    and programs that better address the needs of women and
                    girls. Women’s empowerment can also help to reduce gender
                    inequality and promote greater gender equality, leading to
                    more balanced relationships between men and women and
                    positive impacts on women’s health, well-being, and overall
                    quality of life.
                  </p>
                  <p>
                    As you read about the challenges facing women in India, we
                    hope that you are inspired to take action and make a
                    difference. One way to support women’s empowerment in India
                    is by making a donation to organisations that are dedicated
                    to improving the lives of women and girls in India through
                    education and health care. Ansar Welfare Trust is one such
                    organisation that is committed to this cause. Your donation
                    can help to provide these vital services and make a real
                    difference in the lives of women and girls in India. Whether
                    you can give a small or large amount, every donation makes a
                    difference and can help to create a brighter future
                    for women in India. Please consider making a donation to
                    Ansar Welfare Trust today and join us in the fight for
                    gender equality and women’s empowerment in India.
                  </p>
                </div>
                <div className="col-md-2"> </div>
                {/*  */}
              </div>
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-md-4">
                    <figure>
                      <img
                        src="images/womenempowerment/women_empower_4.jpg"
                        alt=""
                        style={{ width: "100%", height: "303px" }}
                      />
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <figure>
                      <img
                        src="images/womenempowerment/women_empower_5.jpg"
                        alt=""
                        style={{ width: "100%", height: "303px" }}
                      />
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <figure>
                      <img
                        src="images/womenempowerment/women_empower_6.jpg"
                        alt=""
                        style={{ width: "100%", height: "303px" }}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default WomenEmpowerment;
