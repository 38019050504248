import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const Counselling = () => {
  return (
    <>
      <Helmet>
        <title>Counselling</title>
      </Helmet>
      <div
        className="charity-subheader"
        style={{
          backgroundImage: "url(images/counselling/counselling_1.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <span className="black-transparent"></span>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Counselling</h1>
              {/* <p> Write Counselling subtittle or short discription here.</p> */}
            </div>
          </div>
        </div>
      </div>

      {/* Gallery start*/}

      {/* Gallery End */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <figure>
              <img
                src="images/counselling/counselling_1.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/counselling/counselling_2.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/counselling/counselling_3.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
        </div>

        <div class="row">
          <div className="col-md-2"> </div>
          <div class="col-md-8 charity-short-text">
            <p>
              Empowering women is a vital step toward achieving gender equality
              and creating a more just society. Counseling plays a pivotal role
              in helping women overcome challenges, build self-esteem, and
              unlock their full potential. In this guide, we will explore the
              importance of counseling for women’s empowerment and provide
              strategies for effective counseling sessions. Understanding the
              Need for Counseling:
            </p>
            <p>
              <b>1. Addressing Trauma and Abuse: </b> Counseling provides a safe
              space for women to discuss past traumas and experiences of abuse.
              • Therapists can help women process their emotions, heal, and
              develop coping mechanisms.
            </p>
            <p>
              <b>2. Building Self-Esteem and Confidence </b>
              Low self-esteem is a common issue among women. Counseling helps
              individuals recognize their worth. • Therapists work on
              self-image, self-compassion, and assertiveness.
            </p>
            <p>
              <b>3. Career and Personal Development: </b>
              Career counseling supports women in setting and achieving
              professional goals. • Personal development counseling aids in
              identifying strengths and areas for growth.
            </p>
            <h6>
              <b>Empowering Women Through Counseling:</b>{" "}
            </h6>
            <p>
              <b>1. Promoting Self-Awareness: </b>
              Counseling encourages self-reflection, helping women understand
              their thoughts, feelings, and desires. • Increased self-awareness
              leads to better decision-making and personal growth.
            </p>
            <p>
              <b>2. Setting Realistic Goals: </b>
              Therapists assist women in setting achievable goals, both in their
              personal lives and careers. • Goal-setting fosters motivation and
              a sense of purpose.
            </p>
            <p>
              <b>3. Enhancing Communication Skills: </b>
              <p>
                • Effective communication is essential for empowerment.
                Counseling teaches active listening and assertiveness.{" "}
              </p>
              <p>
                • Improved communication leads to healthier relationships and
                greater self-advocacy.
              </p>
            </p>
            <p>
              <b>4. Stress Management: </b>
              <p>
                {" "}
                Counseling equips women with stress management techniques to
                navigate life’s challenges.
              </p>
              <p>
                Stress reduction promotes emotional well-being and resilience.{" "}
              </p>
            </p>
            <p>
              <b>5. Overcoming Gender-Based Challenges: </b>
              Women often face gender-specific issues. Counseling provides tools
              to address these challenges. • Empowerment counseling focuses on
              breaking societal barriers and stereotypes. Counseling is a
              powerful tool for empowering women to overcome obstacles, develop
              their potential, and lead fulfilling lives. By addressing
              emotional, psychological, and societal factors, counselors can
              help women build resilience, self-confidence, and the skills
              necessary to thrive. Empowered women contribute not only to their
              own well-being but also to the betterment of their families,
              communities, and society as a whole.
            </p>
          </div>
          <div className="col-md-2"> </div>
          {/*  */}
        </div>

        <div className="row">
          <div className="col-md-4">
            <figure>
              <img
                src="images/counselling/counselling_4.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/counselling/counselling_5.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
          <div className="col-md-4">
            <figure>
              <img
                src="images/counselling/counselling_6.jpg"
                alt=""
                style={{ width: "100%", height: "303px" }}
              />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};
export default Counselling;
