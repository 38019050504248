import React from "react";
import { Link } from 'react-router-dom';
const OurGallery = () => {
    return (
      <>
        <div className="charity-main-section charity-simple-gallery-full">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="charity-fancy-title">
                  <h2>
                    Our <span className="charity-color">Gallery</span>
                  </h2>
                </div>
                <div className="charity-gallery charity-simple-gallery">
                  <ul className="row">
                    <li className="col-md-2 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2022/20220316_192022.jpg"
                            style={{ width: "100%", height: "303px" }}
                            alt=""
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2022/20220316_192022.jpg"
                          >
                            2022
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                    <li className="col-md-4 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2021/IMG-20210626-WA0030.jpg"
                            alt=""
                            style={{ width: "100%", height: "303px" }}
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2021/IMG-20210626-WA0030.jpg"
                          >
                            2021
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                    <li className="col-md-2 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2020/IMG-20200219-WA0143.jpg"
                            style={{ width: "100%", height: "303px" }}
                            alt=""
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2020/IMG-20200219-WA0143.jpg"
                          >
                            2020
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                    <li className="col-md-4 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2019/IMG-20220506-WA0018.jpg"
                            style={{ width: "100%", height: "303px" }}
                            alt=""
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2019/IMG-20220506-WA0018.jpg"
                          >
                            2019
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                    <li className="col-md-2 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2016/20160213_171326.jpg"
                            style={{ width: "100%", height: "303px" }}
                            alt=""
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2018/20160213_171326.jpg"
                          >
                            2018
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                    <li className="col-md-4 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2017/IMG_20170223_122801.jpg"
                            style={{ width: "100%", height: "303px" }}
                            alt=""
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2017/IMG_20170223_122801.jpg"
                          >
                            2017
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                    <li className="col-md-2 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2017/IMG_20170502_114653.jpg"
                            style={{ width: "100%", height: "303px" }}
                            alt=""
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2017/IMG_20170502_114653.jpg"
                          >
                            2016
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                    <li className="col-md-4 px-0">
                      <figure>
                        <Link href="#">
                          <img
                            src="images/2022/IMG-20220420-WA0107.jpg"
                            style={{ width: "100%", height: "303px" }}
                            alt=""
                          />
                        </Link>
                        <figcaption>
                          <span></span>
                          <Link
                            data-fancybox="gallery"
                            href="images/2022/IMG-20220420-WA0107.jpg"
                          >
                            2015
                          </Link>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default OurGallery;
