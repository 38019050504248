import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const Acknowledgement  = () =>{

    return (
      <>
        <Helmet>
          <title>Acknowledgement</title>
        </Helmet>
        <div className="charity-subheader">
          <span className="black-transparent"></span>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>
                  Awards &<span className="charity-color"> Recognitions </span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="charity-main-content">
          <div className="charity-main-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3>Awards and Recognitions</h3>
                  <p>
                    Won <b>National Award</b> in<b> Community Education </b> for
                    Al Ansar Welfare Trust from{" "}
                    <b>Ministry of Minority Affairs</b>
                    presented by Najma Heptullah in New Delhi in 2015.
                    Felicitated by <b>AFMI</b> (American Foundation of Muslims
                    of Indian Origin) and Delhi Youth Welfare Association{" "}
                    <b>(DYWA)</b>
                    for working and giving coaching and Career Counselling to
                    Walled city girls in 2016. Interview aired on 22 Nov-2017 by
                    E.S.D. (Urdu Service) All India Radio, in Bazme Khwateen
                    (Women Achievers) Extensively covered by different National
                    Dailies as a dedicated social worker.
                  </p>
                  <ul>
                    <li>
                      <b>
                        Global Goodwill Ambassador for Humanitarian for India
                      </b>{" "}
                      in 2018.
                    </li>
                    <li>
                      <b>National Stree Shakti Award by Gantavaya Sansthan </b>
                      on 23 March 2019.
                    </li>
                    <li>
                      <b>
                        Heroes of India by Unnat Bharat social welfare society
                      </b>{" "}
                      on 12 Sep in 2019.
                    </li>
                    <li>
                      <b>Charted Global Peacebuilding Professional</b> from
                      <b>George Washington University for Peace</b> USA in
                      2020-2021
                    </li>
                    <li>
                      <b>Corona warrior</b> Appreciation Certificate from{" "}
                      <b>Delhi Commission for Protection of Child Rights,</b>{" "}
                      Govt. Of NCT Delhi in 2020.
                    </li>
                    <li>
                      <b>Corona Warrior Appreciation</b> by Puranic{" "}
                      <b>Healing and New Socio Economic Development in 2021.</b>
                    </li>
                    <li>
                      <b>Dr Sarojini Naidu,</b> The Nightingale of India
                      International
                      <b>Award for working Women in 2021.</b>
                    </li>
                    <li>
                      <b>
                        CPS-AMBASSADOR FOR PEACE -2022 Centre For Peace Studies
                        –Sri Lanka.
                      </b>
                    </li>
                    <li>
                      <b>THE George Washington University of Peace USA-2022.</b>
                    </li>
                    <li>
                      <b>
                        WOMEN RESILIENCE AWARD 2023 By Akhil Bharat Rachnatmak
                        Samaj N.Delhi.
                      </b>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="charity-team charity-simple-team inner-wrap">
                    <ul className="row" style={{ justifyContent: "center" }}>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-1.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                          {/* <figcaption>
                            <div className="charity-simple-team-media">
                              <Link to="#">
                                <i className="fab fa-facebook-f"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-twitter"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-instagram"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-linkedin-in"></i>
                              </Link>
                            </div>
                            <div className="charity-simple-team-text">
                              <h2>
                                <Link to="#">Jhon Elexander</Link>{" "}
                              </h2>
                              <span>Co-director</span>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipiscing elit sed do eiusmod.
                              </p>
                            </div>
                          </figcaption> */}
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-2.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                          {/* <figcaption>
                            <div className="charity-simple-team-media">
                              <Link to="#">
                                <i className="fab fa-facebook-f"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-twitter"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-instagram"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-linkedin-in"></i>
                              </Link>
                            </div>
                            <div className="charity-simple-team-text">
                              <h2>
                                <Link to="#">Brian Smith</Link>{" "}
                              </h2>
                              <span>Co-director</span>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipiscing elit sed do eiusmod.
                              </p>
                            </div>
                          </figcaption> */}
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-3.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                          {/* <figcaption>
                            <div className="charity-simple-team-media">
                              <Link to="#">
                                <i className="fab fa-facebook-f"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-twitter"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-instagram"></i>
                              </Link>
                              <Link to="#">
                                <i className="fab fa-linkedin-in"></i>
                              </Link>
                            </div>
                            <div className="charity-simple-team-text">
                              <h2>
                                <Link to="#">Brian Smith</Link>{" "}
                              </h2>
                              <span>Co-director</span>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipiscing elit sed do eiusmod.
                              </p>
                            </div>
                          </figcaption> */}
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-4.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-5.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-6.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-7.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-8.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-9.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-10.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                      <li className="col-md-4">
                        <figure style={{ padding: "10px" }}>
                          <Link to="#">
                            <img
                              src="images/awardsandacknowledgement/img-11.jpg"
                              alt=""
                              style={{ height: "300px", objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default Acknowledgement;