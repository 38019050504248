import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
const Coaching = () => {
  return (
    <>
      <Helmet>
        <title>Coaching</title>
      </Helmet>
      <div
        className="charity-subheader"
        style={{
          backgroundImage: "url(images/coaching/coaching_2.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <span className="black-transparent"></span>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Coaching</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery start*/}

      <div className="container-fluid">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-4">
              <figure>
                <img
                  src="images/coaching/coaching_1.jpg"
                  alt=""
                  style={{ width: "100%", height: "303px" }}
                />
              </figure>
            </div>
            <div className="col-md-4">
              <figure>
                <img
                  src="images/coaching/coaching_2.jpg"
                  alt=""
                  style={{ width: "100%", height: "303px" }}
                />
              </figure>
            </div>
            <div className="col-md-4">
              <figure>
                <img
                  src="images/coaching/coaching_3.jpg"
                  alt=""
                  style={{ width: "100%", height: "303px" }}
                />
              </figure>
            </div>
          </div>
        </div>

        <div class="row">
          <div className="col-md-2"> </div>
          <div class="col-md-8 charity-short-text">
            <h2 style={{ textAlign: "center" }}>
              {" "}
              Education - A tool for empowerment{" "}
            </h2>

            <p>
              <h6>
                <b>High School Dropouts and Social Misconceptions</b>
              </h6>
              According to UNICEF, 80 million children out of the 200 million
              admitted in elementary schools are likely to drop out. That’s
              right. 40 percent of the children between the ages of 6 and 14
              would not even complete their elementary education.
            </p>
            <p>
              According to this infographic, dalits, tribal minorities and
              adolescent girls are much more likely to drop out of school. For a
              nation that strives for aggressive rural development, we don’t
              seem to care much about our young, especially the deprived young,
              comprising of dalits, tribals, minorities and adolescent girls.
            </p>
            <p>
              A joint study by Unesco Institute for Statistics and the Global
              Education Monitoring Report has found that 47 million adolescents
              in India have not progressed to upper secondary school. As per the
              data, the country has the highest number of out-of-school
              adolescents in the world.
            </p>
            <p>
              A joint study by Unesco Institute for Statistics and the Global
              Education Monitoring Report has found that 47 million adolescents
              in India have not progressed to upper secondary school. As per the
              data, the country has the highest number of out-of-school
              adolescents in the world.
            </p>
            <p>
              participate fully in the economy, women can have access to better
              paying jobs, which can help to lift them and their families out of
              poverty. Empowering women can also give them more control over
              their own lives, allowing them to make decisions about their own
              health, well-being, and future, which can lead to increased
              self-esteem and confidence, as well as improved mental
              and physical health.
              <p>
                Girls are expected to stay home and raise a family, whereas men
                are expected to work. Given this a lot of parents don’t see the
                need to send their girl children to school.
              </p>
              <p>
                Girl who do happen to work will not support their parents after
                they are married into another house, ergo, their education isn’t
                a priority to a lot of parents. On the other hand, investing on
                their male child’s education will be beneficial in the future
                because he’ll one day start to run the household and look after
                his parents.
              </p>
              <p>
                In poor households girl children are expected to help with
                chores instead of throwing away time in school.
              </p>
              <p>
                Learning household chores is a priority because it increases the
                girl’s value in the marriage market, thus, a lot of parents
                prefer their girl child stay home and learn what will really be
                of any use to her.
              </p>
              <p>
                Fear of safety while going to school Lack of Good Educational
                Facilities and Gender Inequality in Education
              </p>
            </p>
            <b>
              Lack of Good Educational Facilities and Gender Inequality in
              Education
            </b>
            <p>
              • Going to school is a big step, but if the quality of education
              is poor it reduces their future prospects. Especially in rural
              areas, this is a major problem as there is no access to good
              quality education.
            </p>
            <p>
              • In some areas, children have to travel miles to get to school.
              In addition to this, economic and financial situation of families
              in rural areas drive children toward labor vs education.
            </p>
            <p>
              • Gender inequality in education is a major roadblock in getting
              girls to go to schools as families in rural areas are generally of
              lower economic status and don’t see the point in educating a child
              when there are no real opportunities post graduation. This is
              especially the case for a girl, who would rather be expected to
              support the family.
            </p>
            <p>
              • The quality of education, distance, lack of female teachers and
              poor sanitation are often quoted as reasons for leaving by many
              parents.
            </p>
            <p>
              • A majority of government-funded schools do not comply with basic
              standards set, not all teachers are professionally trained and
              even electricity is a luxury in some rural areas. Unless parents
              of the girl child see value in educating their child, they will
              resist doing so.
            </p>
          </div>
          <div className="col-md-2"> </div>
        </div>

        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-4">
              <figure>
                <img
                  src="images/coaching/coaching_4.jpg"
                  alt=""
                  style={{ width: "100%", height: "303px" }}
                />
              </figure>
            </div>
            <div className="col-md-4">
              <figure>
                <img
                  src="images/coaching/coaching_5.jpg"
                  alt=""
                  style={{ width: "100%", height: "303px" }}
                />
              </figure>
            </div>
            <div className="col-md-4">
              <figure>
                <img
                  src="images/coaching/coaching_6.jpg"
                  alt=""
                  style={{ width: "100%", height: "303px" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery end */}
    </>
  );
};
export default Coaching;
