import React from 'react'
// import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      {/* <footer
        id="charity-footer"
        className="charity-footer-one charity-footer-widget"
      > */}
      {/* <div
        className="charity-footer-widget"
        style={{ border: "1px solid red" }}
      > */}
      {/* <div className="border border-danger mb-10" style={{}}> */}
        <div
          className="row charity-footer-widget"
          style={{ padding: "0px 10px", height: "50px",width:"100%" }}
        >
          <p style={{ color: "white", lineHeight:"50px",textAlign:"center",fontSize:"10px" }}>
            {" "}
            Copyright © 2023-2030 Ansar Foundation | All Rights Reserved
          </p>
        </div>
      {/* </div> */}
      {/* </div> */}
      {/* </footer> */}
    </>
  );
}

export default Footer