import React from 'react'
import MainMid from './MainMid';

const Vision = () => {
  return (
    <>
      <div class="charity-main-section charity-modren-services-full">
        <div className="charity-fancy-title">
          <h2 style={{ margin: "20px 0px 20px 0px" }}> Vision</h2>
        </div>

        <MainMid
          heading="To create a just and equitable society where everyone has access to basic human rights, opportunities, and resources."
          title="WE HAVE BEEN SERVING SOCIETY SINCE 2014."
          para="Al Ansar has been with the society in various forms like women empowerment and Child Development through education Healthcare orphancare."
          img="images/vision/vision.png"
        />
      </div>
    </>
  );
}

export default Vision