import React from "react";
import MainMid from "./MainMid";
// import { Link } from 'react-router-dom';
const title1="";
const title2 = "";
const title3 = "";
const content1="";
const content2 = "";
const MissionCard = () => {
  return (
    <>
      <div class="charity-main-section charity-modren-services-full">
        <div className="charity-fancy-title">
          <h2 style={{ margin: "20px 0px 20px 0px" }}> Mission</h2>
        </div>

        {/* <MainMid
          title1={title1}
          title2={title2}
          title3={title3}
          content1={content1}
          content2={content2}
        /> */}
        <MainMid
          heading=" To empower Women and children of underserved communities
                  through education, healthcare, emergency relief, orphan care,
                  skill development, and social welfare initiatives."
          title="We have been serving society since 2014."
          para=" Al Ansar has been with the society in various forms like women
                empowerment and Child Development through education Healthcare
                orphancare."
          img="images/IMG-20220413-WA0014.jpg"
        />
      </div>
    </>
  );
};
export default MissionCard;
