

const fatima = {
  name: "Fatima",
  title: "A Dependent, Lonely, Maimed Woman",
  content: `Fatima is a resident of Laal Kuan, Old Delhi. She lives a
            difficult life with her husband due to financial constraints
            and no sustained source of income. Her elderly husband, who is
            a skilled zardozi embroidery worker, is jobless since months
            now as the pandemic has led to unemployment and lack of demand
            of labour. The couple does not have any children to depend upon
            for their monetary needs and totally rely upon external help
            for survival. Fatima met a tragic incident when she slipped
            from the staircase approximately eight years ago in 2014
            leading to a damaged spinal cord and displacement of the disc.
            She remained bed ridden for very long. Repercussions of this
            injury are still felt as it makes it difficult for her to carry
            out the regular household work. Due to this tremendous health
            issue, she regularly has to visit the doctor and is being
            treated at the Hindustan Medicare Hospital, Jama Masjid. Fatima
            and her husband live in a rented home and are totally dependent
            for their daily needs, medicines and other regular expenses. Al
            Ansar Welfare Trust has been helping Fatima financially since
            years now not only with her huge medical bills but with also
            the routine needs of ration and rent by regularly taking the
            responsibility of the elderly couple. Much more can be done if
            more funds are available so that a proper source of livelihood
            can be generated for them."`,
  image: "ourImpact_fatima.jpg",
};

const shruti = {
  name: "Shruti ",
  isNameChanged: true,
  title: "",
  content: `An Economically Weak Woman strengthened with the help of AWT
            Fifteen years ago everything was working well when Shruti  depended upon her cows and
            cattle as a means of livelihood which was profitable and comforting. But since her husband’s
            business wrapped up, the family met with an array of monetary problems. Shruti has
            been living in Old Delhi for decades with her husband and two children. Shruti husband
            started working in a garage and car shop to meet the requirements of daily bread and butter
            which is why they could only manage to educate her daughter up till class 12th and now as a
            grown up is herself a mother of two kids. On the other hand Shruti son could only attend
            school up till class 10th and now works in a local shop earning only a meager income. In these
            challenging times, Al Ansar Welfare Trust acted as a supporter and benefactor to the poor family
            and still continues to be at their aid. Meeting the rent requirements, ration, medical expenses and
            other household works would have been impossible without the organization’s support. If
            Shruti can be trained and provided with the necessary capital and skill she can support her
            children and grand- children and educate them towards gaining a successful life ahead.`,
  image: "ourImpact_shruti.jpg",
};

const mehak = {
  name: "Mehak ",
  isNameChanged: true,
  title: "Another tale of Covid-led financial problem",
  content: `Residents of Laxmi Nagar, Mehak lives with her husband and daughter. Her husband lost his parents and brother in quick succession and went into depression.  Due to the Covid lockdown, like many others, he lost his job and this ended up adding to his depression condition.This led to them facing financial problem.Because of the same, Mehak was forced to take up tuitions for the neighbourhood kids in the evening. That is when Al Ansar started helping them monthly. She reached out to them with financial help. She also helped them with their daughter’s studies – school fees and library books. In order to be able to support her family better, Mehak is currently looking for a job.`,
  image: "ourImpact_mehak.jpg",
};

const riya = {
  name: "Riya ",
  isNameChanged: true,
  title: "Case of making ends meet",
  content: `We couldn’t get Riya on call due to her health issues. But her husband was ready to talk to us. Riya and her husband Badar have been facing financial problems ever since he got retired from his job at accounting a few years ago. Residents of Churiwalan, Old Delhi, they’ve 5 kids. All have cleared their senior secondary exams and are on a look out for jobs. Two of them have also got married.  Riya faces health issues and stays unwell. She needs help and support of the rest of her family to keep well. With sky-high prices, increasing inflation and so many mouths to feed, they’re finding it difficult to make the ends meet. Now at the age of 39, Badar says he is still ready to work to be able to get some food on the plate for his family`,
  image: "ourImpact_riya.jpg",
};

const marya = {
  name: "Marya ",
  isNameChanged: true,
  title: "",
  content: `Marya (Name changed) is a 22-year-old widow, of Bihar. Her husband realized
            he wasn't going to make it,"Razzak” he asked her to take care of their two
            daughters, then he left Marya all alone in the world before 15 years." Yet she
            has two young children of her own to support, who died at the age of 30, most
            probably from critical disease. Life has been difficult for some time. While her
            husband was ill, Marya nursed him. That meant she spent less time with her
            children and was unable to grow enough grain for her family's needs. Her fields (2
            beegha) yields have also been low. In a bid to improve the crop she tried to irrigate
            it properly, but she is hampered by the lack of know-how. Still, Marya strongly
            believes that, together with her late husband's 18-year-old daughter, she can
            manage the farm successfully in the future.
            The pandemic has wrought havoc on the entire world. Pessimism, suffering,
            unemployment, hunger and poverty resound in all corners. To survive is a physical,
            mental and financial battle. And every family and individual has an anecdote to
            narrate that speaks volumes about their combat strategy. With the pandemic, she
            had no means to sustain herself. The ration, and cash transfer by “Al – Ansar
            Welfare Trust” are a real relief package to her. With a smile, she said, “we will be
            taken care of. If not the government, then God sends angels and she found angel
            this time in the form of “Al – Ansar Welfare Trust.” Delhi whose supportive
            intervention are on in this location all the way from the capital city of India i.e Delhi.`,
  image: "ourImpact_marya.jpg",
};

const zara = {
  name: "Zara ",
  isNameChanged: true,
  title: "",
  content: `
  This is a story of a 20 year girl living in a family of 9 members including her parents, five
sisters and a brother. The lone bread earner of the family is her father who sells toys
outside marriage halls and mostly earns seasonally during marriage season in the city.
It becomes highly painful for a family whose single son and  support as alternate bread winner
is in judicial custody. Zara was in grade 11 th and was living with her parents in Daryaganj,
New Delhi locality when she came to meet us in our ration distribution in the beginning of
2020 and the complete lockdown in the country made things harsher for the family as marriage
halls were closed down and her father could not sell toys for their living. This situation of
lockdown brought them in a position ofcomplete hand to mouth where the family was bound to
 live in hunger situation.Zara could not bear it at all to leave her parents and siblings in a dire
 situation of hunger and distress, so she herself came to explore, after hearing from one of her
 neighbor, that “Al-Ansar Welfare Society” a philanthropic institution situated in Daryaganj,
New Delhi is supporting poor section of the society. Zara reached out to the NGO’s distribution
 center and started collecting ration on routine basis. As the bread issue resolved by the NGO
 then Ms. Asma - head of the NGO sat with Ms. Zara and came to know the complete story of
 her family. Ms. Asma said that they are training such girls and women with valuable skills,
and motivating  them to go for viable livelihood opportunities in growth sectors. The project
will improve their chances to increase the income and improve their safety and security by

 facilitating access to alternative livelihoods. Al- Ansar welfare trust also focuses on the
personality development and reinstating self- belief of such girls and women of the society.

Majority of girls and young women in the program either had no livelihood activity or were
engaged in activities that were not particularly beneficial or safe for them. Although this group of young women is inexperienced in enterprise management, they are openly excited about
engaging in a rewarding profession that can enhance their economic independence. It was clear
from the beginning that the girls are interested in starting earnings as soon as possible despite
their need for in-depth training. As a result, the type of training has to be designed as per the
need of the girls and women or they have to be enrolled in such skill development program
which could give them an opportunity to earn their livelihood with pride and in a dignified
manner.
Zara is provided with ration support for the family and efforts were made to rehabilitate this
girl so that she could build her career as she was not able to read or write anything in the
beginning though she was very sincere and hardworking. The NGO helped her enroll in
NCUPUL for her diploma course in computer which has given her a platform to develop
computer skills in this Al – Ansar welfare trust while working as an employee. Also it has given
her a financial support to look after her family because during lockdown her parents have
suffered several diseases and could not work outside. Zara and two other sisters are unmarried
which is a bigger concern for the parents.
Zara has improved her computer skill and language proficiency by taking up the
responsibilities of the NGO single handed. She seems to be more confident and committed to
bring meaningful change in the life of more such girls and other women from lower strata of the
society.
  `,
  image: "ourImpact_zara.jpg",
};

export const ourImpactContent = [fatima, shruti,mehak, riya, marya, zara];
