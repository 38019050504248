import React from "react";
import { Link } from "react-router-dom";
const MainMid = ({ heading, title, para, img }) => {
  return (
    <>
      <div className="charity-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="charity-fancy-title">
                <p>
                  {/* To empower Women and children of underserved communities
                  through education, healthcare, emergency relief, orphan care,
                  skill development, and social welfare initiatives. */}
                  {heading}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <figure className="short-text-thumb">
                <img
                  src={img}
                  alt=""
                  style={{ width: "100%", height: "300px" }}
                />
              </figure>
            </div>
            <div className="col-md-6 charity-short-text">
              <h2>{title}</h2>

              <p>
                {/* Al Ansar has been with the society in various forms like women
                empowerment and Child Development through education Healthcare
                orphancare. */}
                {para}
              </p>

              <Link
                style={{ textDecoration: "none" }}
                to="#"
                className="charity-more-btn"
              >
                <i className="fas fa-arrow-right"></i> Read More
              </Link>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid">
            <div className="col-md-12">
              <div className="charity-services charity-simple-services">
                <ul className="row">
                  <li className="col-md-3">
                    <i className="fas fa-book charity-color"></i>
                    <div className="charity-simple-services-text">
                      <h2>Education and Skill Development</h2>
                      <p>
                        We provide education and skill development programs to
                        equip individuals with the knowledge and skills needed
                        for better livelihood opportunities. This includes
                        formal and informal education, vocational training, and
                        career guidance.
                      </p>
                       <Link
                              style={{ textDecoration: "none" }} to="#" className="charity-service-link">
                        Read More
                      </Link>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fas fa-book charity-color"></i>
                    <div className="charity-simple-services-text">
                      <h2>Healthcare</h2>
                      <p>
                        We run medical camps, clinics, and health awareness
                        programs to improve the health and well-being of
                        underserved communities. We also provide medical
                        assistance and support to those in need, including
                        access to essential medicines and medical facilities.
                      </p>
                       <Link
                              style={{ textDecoration: "none" }} to="#" className="charity-service-link">
                        Read More
                      </Link>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fas fa-book charity-color"></i>
                    <div className="charity-simple-services-text">
                      <h2>Emergency Relief</h2>
                      <p>
                        We respond promptly to natural disasters, conflicts, and
                        other emergencies by providing emergency relief in the
                        form of food, water, shelter, and medical assistance to
                        affected communities. Our rapid response team ensures
                        efficient and effective relief operations.
                      </p>
                       <Link
                              style={{ textDecoration: "none" }} to="#" className="charity-service-link">
                        Read More
                      </Link>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fas fa-book charity-color"></i>
                    <div className="charity-simple-services-text">
                      <h2>Orphan Care</h2>
                      <p>
                        We support orphaned children by providing them with
                        basic necessities such as food, shelter, education, and
                        emotional support. We aim to reintegrate orphans into
                        society by providing them with a nurturing environment
                        and access to opportunities for holistic development.
                      </p>
                       <Link
                              style={{ textDecoration: "none" }} to="#" className="charity-service-link">
                        Read More
                      </Link>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fas fa-book charity-color"></i>
                    <div className="charity-simple-services-text">
                      <h2> Social Welfare</h2>
                      <p>
                        We promote social welfare through various initiatives,
                        including support for women empowerment, elderly care,
                        disability assistance, and advocacy for social justice
                        and human rights. Our programs focus on empowering
                        marginalized and vulnerable groups, ensuring inclusivity
                        and equality.
                      </p>
                       <Link
                              style={{ textDecoration: "none" }} to="#" className="charity-service-link">
                        Read More
                      </Link>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fas fa-handshake charity-color"></i>
                    <div className="charity-simple-services-text">
                      <h2>SHG GROUP </h2>
                      <p>
                        Our efforts have resulted in a significant positive
                        impact on the lives of individuals and communities we
                        serve. We are working with NULM on different trades like
                        Paper bags, Jute Bags, artificial jewelry and soaps and
                        hand washs etc.
                      </p>
                       <Link
                              style={{ textDecoration: "none" }} to="#" className="charity-service-link">
                        Read More
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
      </div>
    </>
  );
};
export default MainMid;
