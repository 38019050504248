import React from "react";
import { ourImpactContent } from "../Utils/ourImpactContent";

const OurImpact = () => {
  return (
    <>
      <div className="charity-subheader">
        <span className="black-transparent"></span>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>
                Our <span className="charity-color"> impact </span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="charity-main-content">
        <div className="charity-main-section">
          <div className="container">
            {/* <div className="col-md-12"> */}
            <div className="charity-fancy-title"></div>

            {/* <div class="row"> */}
            {ourImpactContent.map((val, i) => (
              <>
                <div
                  class="row"
                  key={i}
                  style={{ border: "1px dashed black", marginTop: "15px" }}
                >
                  <div
                    class="col-lg-3 "
                    style={{ marginTop: "5px" }}
                    //  style={{
                    //    display: "flex",
                    //    flexDirection: "column",
                    //    justifyContent: "center",
                    //    alignItems: "center",
                    //  }}
                  >
                    {/* <figure class="short-text-thumb"> */}
                    <img
                      src={`images/ourImpact/${val.image}`}
                      //  src={`images/ourImpact/ourImpact_mehak`}
                      alt=""
                      style={{ width: "193px", height: "170px" }}
                    />
                    {/* </figure> */}

                    <h6>
                      {val.name} {val.isNameChanged && "(Name changed)"}
                    </h6>
                  </div>
                  <div class="col-lg-9 charity-short-text">
                    <h4> {val.title}</h4>

                    <p>{val.content}</p>

                    {/* <Link to="#" class="charity-more-btn"><i class="fas fa-arrow-right"></i> Read More</Link>  */}
                  </div>
                </div>
                <hr />
              </>
            ))}

            {/* </div> */}

            {/* </div> */}
            <div className="row" style={{ marginTop: "15px" }}>
              <div className="charity-fancy-title">
                <h3>
                  {" "}
                  <b>Ansar and Al Ansar SHG (Swayam Sahayata Samuh)</b>
                </h3>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_1.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_2.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_3.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_4.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_5.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_6.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_7.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_8.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_9.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_10.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_11.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_12.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_13.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_14.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_15.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_16.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_17.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_18.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_19.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_20.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_21.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_22.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_23.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_24.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
              <div className="col-md-3">
                <figure>
                  <img
                    src="images/collision/collision_25.jpg"
                    alt=""
                    style={{ width: "100%", height: "303px" }}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurImpact;
