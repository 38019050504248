import React from "react";
import { Link } from "react-router-dom";
const Volunteers = () => {
  return (
    <>
      <div className="charity-main-section charity-simple-team-full">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="charity-fancy-title">
                <h2>Our Volunteers</h2>
              </div>
              <div className="charity-team charity-simple-team">
                <ul className="row">
                  <li className="col-md-4">
                    <figure style={{ height: "450px" }}>
                      {" "}
                      <Link style={{ textDecoration: "none" }} to="#">
                        <img src="images/volunteer/volunteer_1.jpg" alt="" />
                      </Link>
                      <figcaption>
                        <div className="charity-simple-team-media">
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-twitter"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-instagram"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-linkedin-in"></i>
                          </Link>
                        </div>
                        <div className="charity-simple-team-text">
                          <h2>
                            {" "}
                            <Link style={{ textDecoration: "none" }} to="#">
                              Anam
                            </Link>
                          </h2>
                          <span>Executive Member</span>
                          {/* <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit sed do eiusmod.
                          </p> */}
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4">
                    <figure style={{ height: "450px" }}>
                      {" "}
                      <Link style={{ textDecoration: "none" }} to="#">
                        <img
                          src="images/volunteer/volunteer_2.jpg"
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                             objectPosition: "center center"
                          }}
                        />
                      </Link>
                      <figcaption>
                        <div className="charity-simple-team-media">
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-twitter"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-instagram"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-linkedin-in"></i>
                          </Link>
                        </div>
                        <div className="charity-simple-team-text">
                          <h2>
                            {" "}
                            <Link style={{ textDecoration: "none" }} to="#">
                              Misbah Naaz
                            </Link>
                          </h2>
                          <span>Volunteer</span>
                          {/* <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit sed do eiusmod.
                          </p> */}
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4">
                    <figure style={{ height: "450px" }}>
                      {" "}
                      <Link style={{ textDecoration: "none" }} to="#">
                        <img
                          src="images/volunteer/volunteer_3.jpg"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "scale-down",
                          }}
                          alt=""
                        />
                      </Link>
                      <figcaption>
                        <div className="charity-simple-team-media">
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-twitter"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-instagram"></i>
                          </Link>
                          <Link style={{ textDecoration: "none" }} to="#">
                            <i className="fab fa-linkedin-in"></i>
                          </Link>
                        </div>
                        <div className="charity-simple-team-text">
                          <h2>
                            {" "}
                            <Link style={{ textDecoration: "none" }} to="#">
                              Tayyaba
                            </Link>
                          </h2>
                          <span>Volunteer</span>
                          {/* <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit sed do eiusmod.
                          </p> */}
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
              {/* <div className="charity-loadbtn"> <Link
                              style={{ textDecoration: "none" }} to="#">Load More</Link></div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Volunteers;
