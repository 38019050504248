import React from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";

const Header = () => {
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
console.log("pathname", pathname);
console.log("splitLocation", splitLocation);
const ourWorkArray = [
  "women-empowerment",
  "coaching",
  "counselling",
  "capicity-building",
  "standing-tall-in-the-society",
];
const educationArray = [
  "coaching",
  "counselling",
];
const SupportersArray = ["acknowledgement", "partner"];

  return (
    <>
      <header id="charity-header" className="charity-header-one ">
        <div className="charity-top-strip">
          <div className="container">
            <div className="row">
              <aside className="col-6 charity-strip-text">
                <p>Explore The charity</p>
                <a href="tel:9136461553">
                  <span>
                    <i className="fas fa-phone fa-rotate-90"></i> 9136461553
                  </span>
                </a>
                <span>
                  <i className="far fa-envelope"></i>{" "}
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="alansar268@gmail.com"
                  >
                    alansar268@gmail.com
                  </NavLink>{" "}
                </span>
              </aside>
              <aside className="col-6">
                <div className="float-right">
                  <ul className="charity-social-network">
                    <li>
                      <NavLink
                        to="https://www.facebook.com/alansar.welfaretrust?mibextid=ZbWKwL"
                        className="fab fa-facebook-f"
                        target="__blank"
                        style={{ textDecoration: "none" }}
                      ></NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        to="https://www.instagram.com/invites/contact/?i=1mb9o5zm55nmd&utm_content=o25akyl"
                        className="fab fa-instagram"
                        target="__blank"
                        style={{ textDecoration: "none" }}
                      ></NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        to="https://pin.it/2lH7lI1"
                        className="fab fa-pinterest-p"
                        target="__blank"
                        style={{ textDecoration: "none" }}
                      ></NavLink>{" "}
                    </li>
                    {/* <li>
                       <NavLink
                              style={{ textDecoration: "none" }}to="#" className="fab fa-linkedin-in"></NavLink>{" "}
                    </li> */}
                    <li>
                      <NavLink
                        to="https://twitter.com/AsmaArshi?t=pUY978DHbBBFC_fHciF5uQ&s=08"
                        className="fab fa-twitter"
                        target="__blank"
                        style={{ textDecoration: "none" }}
                      ></NavLink>{" "}
                    </li>
                  </ul>
                  {/* <NavLink
                    style={{ textDecoration: "none" }}
                    to="#"
                    className="charity-strip-btn charity-bgcolor"
                  >
                    Donate Now
                  </NavLink> */}
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div
          className="charity-main-header"
          style={{
            backgroundColor: "#212529cb",
            // height: "100px",
          }}
        >
          <div className="container">
            <div className="row">
              <aside className="col-3">
                {/*  <NavLink
                              style={{ textDecoration: "none" }}to="/" className="charity-logo"> */}
                <img
                  src="images/20220126_123410.jpg"
                  style={{ width: "80px", height: "80px", marginTop: "8px" }}
                  alt=""
                />
                {/* </NavLink>{" "} */}
              </aside>
              {/* <aside className="col-3"> <NavLink
                              style={{ textDecoration: "none" }}to="/" className="charity-logo text-danger " style={{fontSize:"20px"}}>Ansar Welfare</NavLink> </aside> */}

              <aside className="col-9">
                <div className="float-right">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="#menu"
                    className="menu-link active"
                  >
                    <span></span>
                  </NavLink>
                  <nav id="menu" className="menu charity-navigation">
                    <ul>
                      <li className={splitLocation[1] === "" ? "active" : ""}>
                        <NavLink style={{ textDecoration: "none" }} to="/">
                          Home
                        </NavLink>{" "}
                      </li>
                      <li
                        className={
                          splitLocation[1] === "our-team" ? "active" : ""
                        }
                      >
                        <NavLink style={{ textDecoration: "none" }} to="#">
                          About us
                        </NavLink>
                        <ul
                          className="children"
                          // style={{ backgroundColor: "black" }}
                        >
                          <li
                            className={
                              splitLocation[1] === "our-team" ? "active" : ""
                            }
                          >
                            <NavLink
                              style={{ textDecoration: "none" }}
                              to="/our-team"
                            >
                              Team
                            </NavLink>{" "}
                          </li>
                          {/* <li>
                             <NavLink
                              style={{ textDecoration: "none" }}to="/founders">Founder</NavLink>{" "}
                          </li> */}
                          {/* <li> <NavLink
                              style={{ textDecoration: "none" }}to="/EventDetail">Vision</NavLink> </li> */}
                        </ul>
                      </li>
                      <li
                        className={
                          ourWorkArray.includes(splitLocation[1])
                            ? "active"
                            : ""
                        }
                      >
                        <NavLink style={{ textDecoration: "none" }} to="#">
                          Our Work
                        </NavLink>
                        <ul className="children" style={{ width: "230px" }}>
                          <li
                            className={
                              splitLocation[1] === "women-empowerment"
                                ? "active"
                                : ""
                            }
                          >
                            <NavLink
                              style={{ textDecoration: "none" }}
                              to="/women-empowerment"
                            >
                              Women empowerment
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              className={
                                educationArray.includes(splitLocation[1])
                                  ? "active"
                                  : ""
                              }
                              style={{ textDecoration: "none" }}
                            >
                              Education{" "}
                            </NavLink>{" "}
                            <ul
                              className="children"
                              // style={{ backgroundColor: "black" }}
                            >
                              <li
                                className={
                                  splitLocation[1] === "coaching"
                                    ? "active"
                                    : ""
                                }
                              >
                                <NavLink
                                  style={{ textDecoration: "none" }}
                                  to="/coaching"
                                  style={{ textDecoration: "none" }}
                                >
                                  Coaching
                                </NavLink>{" "}
                              </li>
                              <li
                                className={
                                  splitLocation[1] === "counselling"
                                    ? "active"
                                    : ""
                                }
                                style={{ textDecoration: "none" }}
                              >
                                <NavLink
                                  style={{ textDecoration: "none" }}
                                  to="/counselling	"
                                >
                                  Counselling{" "}
                                </NavLink>{" "}
                              </li>
                            </ul>
                          </li>
                          <li
                            className={
                              splitLocation[1] === "capicity-building"
                                ? "active"
                                : ""
                            }
                          >
                            <NavLink
                              to="/capicity-building"
                              style={{ textDecoration: "none" }}
                            >
                              Capicity building
                            </NavLink>{" "}
                          </li>
                          <li
                            className={
                              splitLocation[1] ===
                              "standing-tall-in-the-society"
                                ? "active"
                                : ""
                            }
                          >
                            {/*  <NavLink
                              style={{ textDecoration: "none" }}to="/standing-tall-in-the-society">
                              Standing Tall in the society
                            </NavLink>{" "} */}
                          </li>
                          {/* <li>
                             <NavLink
                              style={{ textDecoration: "none" }}to="/widow_pension">Widow Pension</NavLink>{" "}
                          </li> */}
                        </ul>
                      </li>
                      {/* <li>
                         <NavLink
                              style={{ textDecoration: "none" }}to="#">Blog</NavLink>
                        <ul className="children">
                          <li>
                             <NavLink
                              style={{ textDecoration: "none" }}to="/BlogLarge">Cause List</NavLink>{" "}
                            <ul className="children">
                              <li>
                                 <NavLink
                              style={{ textDecoration: "none" }}to="/Blog_medium">AAA</NavLink>{" "}
                              </li>
                              <li>
                                 <NavLink
                              style={{ textDecoration: "none" }}to="/Blog_medium">BBB</NavLink>{" "}
                              </li>
                            </ul>
                          </li>
                         
                        </ul>
                      </li> */}
                      <li
                        className={
                          SupportersArray.includes(splitLocation[1])
                            ? "active"
                            : ""
                        }
                      >
                        <NavLink style={{ textDecoration: "none" }} to="#">
                          {/* Supporters */}
                          Acknowledgement
                        </NavLink>
                        <ul
                          className="children"
                          // style={{ backgroundColor: "black" }}
                        >
                          <li
                            className={
                              splitLocation[1] === "acknowledgement"
                                ? "active"
                                : ""
                            }
                          >
                            <NavLink
                              style={{ textDecoration: "none" }}
                              to="/acknowledgement"
                            >
                              {/* acknowledgement */}
                              Award & Recognitions
                            </NavLink>{" "}
                          </li>
                          {/* <li>
                             <NavLink
                              style={{ textDecoration: "none" }}to="/well-wisher">Well Wisher</NavLink>{" "}
                          </li> */}

                          <li
                            className={
                              splitLocation[1] === "partner" ? "active" : ""
                            }
                          >
                            {/* <NavLink
                              style={{ textDecoration: "none" }}
                              to="/partner"
                            >
                              Partner
                            </NavLink>{" "} */}
                          </li>
                        </ul>
                      </li>
                      {/* <li> <NavLink
                              style={{ textDecoration: "none" }}to="#">Pages</NavLink> 
                                        <ul className="children">
                                            <li> <NavLink
                              style={{ textDecoration: "none" }}to="/about">About Us</NavLink> </li>
                                            <li> <NavLink
                              style={{ textDecoration: "none" }}to="/prayer_list">Prayer List</NavLink> </li>
                                            <li> <NavLink
                              style={{ textDecoration: "none" }}to="/prayer_grid">Prayer Grid</NavLink> </li>
                                            <li> <NavLink
                              style={{ textDecoration: "none" }}to="/Prayer_detail">Prayer Detail</NavLink> </li>
                                            <li> <NavLink
                              style={{ textDecoration: "none" }}to="/notfoundPgae">404 Error</NavLink> </li>
                                            <li> <NavLink
                              style={{ textDecoration: "none" }}to="/SearchResult">Search Result</NavLink> </li>
                                        </ul>
                                    </li> */}
                      <li
                        className={
                          splitLocation[1] === "gallery" ? "active" : ""
                        }
                      >
                        <NavLink
                          style={{ textDecoration: "none" }}
                          to="/gallery"
                        >
                          OurGallery
                        </NavLink>{" "}
                      </li>
                      <li
                        className={
                          splitLocation[1] === "impact" ? "active" : ""
                        }
                      >
                        <NavLink
                          style={{ textDecoration: "none" }}
                          to="/impact"
                        >
                          Our Impact
                        </NavLink>{" "}
                      </li>

                      <li
                        className={
                          splitLocation[1] === "contact" ? "active" : ""
                        }
                      >
                        <NavLink
                          style={{ textDecoration: "none" }}
                          to="/contact"
                        >
                          Contact
                        </NavLink>{" "}
                      </li>
                    </ul>
                  </nav>
                  {/* <ul className="charity-header-options">
                                <li> <NavLink
                              style={{ textDecoration: "none" }}to="#" data-toggle="modal" data-target="#searchModal"><i className="fas fa-search"></i></NavLink> </li>
                                <li> <NavLink
                              style={{ textDecoration: "none" }}to="#"><i className="fab fa-opencart"></i></NavLink>  <div className="charity-cart-box"> <p>No products in the cart.</p> </div> </li>
                            </ul> */}
                </div>
              </aside>
            </div>
          </div>
        </div>
      </header>

      <div
        className="modal fade searchmodal"
        id="searchModal"
        // tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <NavLink
                to="#"
                className="charity-close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </NavLink>
              <form>
                <input
                  type="text"
                  value="Type Your Keyword"
                  //   onblur="if(this.value == '') { this.value ='Type Your Keyword'; }"
                  //   onfocus="if(this.value =='Type Your Keyword') { this.value = ''; }"
                />
                <input type="submit" value="" />
                <i className="fa fa-search"></i>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header